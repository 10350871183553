import React, { useEffect, useState } from 'react'
import { RedocStandalone } from 'redoc'
import { utilService } from '../services/util.service'

export function HomePage() {
    const [requestOption, setRequestOption] = useState(null)
    useEffect(() => {
        setTimeout(() => {
            // utilService.locateRequestDropdowns(document).forEach((element) => {
            //     element.addEventListener('change', handleRequestChange)
            // })
            utilService.locateResponse(document).forEach((element) => {
                element.addEventListener('click', handleResponeClick)
            })
        }, 1000)
    }, [])

    const handleRequestChange = (event) => {
        let resCode =
            event.target.value === 'Unsuccessful request example' ? 400 : 200
        const currEndpoint = utilService.getEndpointEleFromReq(event.target)
        utilService.selectRespone(
            utilService.locateResponse(currEndpoint),
            resCode
        )
    }

    async function handleResponeClick(event) {
        const currEndpoint = utilService.getEndpointEleFromRes(event.target)
        // console.log(currEndpoint)
        const currSelection = utilService.locateRequestDropdown(currEndpoint)
        // console.log(currSelection)
        // console.log(event.target.innerText)
        let resCode =
            event.target.innerText === '200'
                ? 'Successful request example'
                : 'Unsuccessful request example'
        const optEle = await utilService.selectRequest(currSelection, resCode)
            .innerText
        setRequestOption(optEle)
        console.log(optEle)
    }

    return (
        <div className="App">
            <RedocStandalone
                specUrl="Unimastery.json"
                options={{
                    hideDownloadButton: true,
                }}
            />
        </div>
    )
}
