export const utilService = {
    makeId,
    makeLorem,
    getRandomIntInclusive,
    debounce,
    randomPastTime,
    saveToStorage,
    loadFromStorage,
    locateRequestDropdowns,
    locateResponse,
    selectRespone,
    getEndpointEleFromReq,
    selectRequest,
    getEndpointEleFromRes,
    locateRequestDropdown,
}

function makeId(length = 6) {
    var txt = ''
    var possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (var i = 0; i < length; i++) {
        txt += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return txt
}

function makeLorem(size = 100) {
    var words = [
        'The sky',
        'above',
        'the port',
        'was',
        'the color of television',
        'tuned',
        'to',
        'a dead channel',
        '.',
        'All',
        'this happened',
        'more or less',
        '.',
        'I',
        'had',
        'the story',
        'bit by bit',
        'from various people',
        'and',
        'as generally',
        'happens',
        'in such cases',
        'each time',
        'it',
        'was',
        'a different story',
        '.',
        'It',
        'was',
        'a pleasure',
        'to',
        'burn',
    ]
    var txt = ''
    while (size > 0) {
        size--
        txt += words[Math.floor(Math.random() * words.length)] + ' '
    }
    return txt
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}

function randomPastTime() {
    const HOUR = 1000 * 60 * 60
    const DAY = 1000 * 60 * 60 * 24
    const WEEK = 1000 * 60 * 60 * 24 * 7

    const pastTime = getRandomIntInclusive(HOUR, WEEK)
    return Date.now() - pastTime
}

function debounce(func, timeout = 300) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

function saveToStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

function loadFromStorage(key) {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : undefined
}

function locateRequestDropdowns(ele) {
    return ele.querySelectorAll('.react-tabs__tab-panel--selected select')
}

function locateRequestDropdown(ele) {
    return ele.querySelector('.react-tabs__tab-panel--selected select')
}

function locateResponse(ele) {
    return ele.querySelectorAll('.tab-success, .tab-error')
}

function selectRespone(eles, resCode) {
    eles.forEach((element) => {
        if (element.innerText === String(resCode)) {
            element.classList.add('react-tabs__tab--selected')
            element.click()
        } else {
            element.classList.remove('react-tabs__tab--selected')
        }
    })
}

function selectRequest(eles, reqCode) {
    // console.log(eles)
    // console.log(reqCode)
    const optionsArray = [...eles.options]
    const desiredOption = optionsArray.find(
        (option) => option.value === reqCode
    )
    // console.log(desiredOption)
    if (desiredOption) {
        // desiredOption.click()
        desiredOption.selected = true
        eles.value = reqCode
        // console.log(eles)
        // console.log(optionsArray)
    }
    return desiredOption
}

function getEndpointEleFromReq(ele) {
    return ele.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode
}

function getEndpointEleFromRes(ele) {
    return ele.parentNode.parentNode.parentNode.parentNode
}
